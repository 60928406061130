import { Button, Toolbar, Grid} from "@mui/material";
import React, { useState } from "react";

import DadosDaEmpresa from "./components/DadosDaEmpresa";
import { Contatos } from "./components/Contatos";
import { Extras } from "./components/Extras";
import CompanyHeader from "../../common/CompanyHeader";

export function Descubra(props) {
  const {language, responseCompany, url} = props;
  const [dados, setDados] = useState(true);
  const [contatos, setContatos] = useState(false);
  const [extras, setExtras] = useState(false);

  const clearState = (param) => {
    switch (param) {
      case "dados":
        setContatos(false);
        setExtras(false);
        break;
      case "contatos":
        setDados(false);
        setExtras(false);
        break;
      case "extras":
        setDados(false);
        setContatos(false);
        break;
      default:
        setDados(false);
        setContatos(false);
        setExtras(false);
        break;
    }
  };

  return (
    <React.Fragment>
      <Grid
        // position="sticky"
        sx={{
          top: 0,
          //height: "50px",
          backgroundColor: "#FFFFFF",
          boxShadow: "none",
          zIndex: "200",
        }}
      >
        <Grid style={{backgroundColor: "white"}}>
          <CompanyHeader 
            language={language} 
            linkedin={props.linkedin} 
            url={url} 
            responseCompany={responseCompany}
          />
          <Grid>
            <Toolbar
              sx={{
                minHeight: "0px",
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "white"
              }}
            >
              <Button
                disableRipple={true}
                style={
                  dados
                    ? {
                        color: "#F07F2D",
                        fontSize: "14px",
                        fontWeight: 500,
                        borderBottom: "3px solid #F07F2D",
                        borderRadius: "0px",
                        fontFamily: "Inter",
                        textTransform: "none",
                      }
                    : {
                        color: "#475467",
                        fontSize: "14px",
                        fontWeight: 500,
                        fontFamily: "Inter",
                        textTransform: "none",
                      }
                }
                onClick={() => {
                  setDados(true);
                  clearState("dados");
                }}
              >
                {language === "pt-BR" ? "Sobre" : "About"}
              </Button>
              <Button
                disableRipple={true}
                style={
                  contatos
                    ? {
                      color: "#F07F2D",
                      fontSize: "14px",
                      fontWeight: 500,
                      borderBottom: "3px solid #F07F2D",
                      borderRadius: "0px",
                      fontFamily: "Inter",
                      textTransform: "none",
                    }
                  : {
                      color: "#475467",
                      fontSize: "14px",
                      fontWeight: 500,
                      fontFamily: "Inter",
                      textTransform: "none",
                    }
                }
                onClick={() => {
                  setContatos(true);
                  clearState("contatos");
                }}
              >
                {language === "pt-BR" ? "Contatos" : "Contacts"}
              </Button>
              <Button
                disableRipple={true}
                style={
                  extras
                    ? {
                        color: "#F07F2D",
                        fontSize: "14px",
                        fontWeight: 500,
                        borderBottom: "3px solid #F07F2D",
                        borderRadius: "0px",
                        fontFamily: "Inter",
                        textTransform: "none",
                      }
                    : {
                        color: "#475467",
                        fontSize: "14px",
                        fontWeight: 500,
                        fontFamily: "Inter",
                        textTransform: "none",
                      }
                }
                onClick={() => {
                  setExtras(true);
                  clearState("extras");
                }}
              >
                {language === "pt-BR" ? "Extras" : "Extras"}
              </Button>
            </Toolbar>
          </Grid>
        </Grid>
      </Grid>
      {dados && <DadosDaEmpresa responseCompany={responseCompany} url={url} language={language}/>}
      {contatos && <Contatos responseCompany={responseCompany} url={url} language={language}/>}
      {extras && <Extras responseCompany={responseCompany} url={url} language={language}/>}
    </React.Fragment>
  );
}
