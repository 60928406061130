import { setLocalStorage } from "../utils/localStorage";
import {
  apiGateway,
  cookiesWorkflow,
  multipartApiGateway,
} from "./axiosClients";
import axios from "axios";

export function signIn(credentials) {
  return new Promise((resolve, reject) => {
    apiGateway
      .post(`/auth/v1/login`, credentials)
      .then((res) => {
        apiGateway
          .post(
            `/auth/v1/authenticate?company=${res.data.companies[0].companyName}`,
            credentials
          )
          .then((response) => {
            multipartApiGateway.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${response.data.token}`;
            apiGateway
              .get(`/auth/v1/verify`, {
                headers: { authorization: `${response.data.token}` },
              })
              .then((resp) => {
                apiGateway.defaults.headers.common[
                  "Authorization"
                ] = `Bearer ${response.data.token}`;
                setLocalStorage("user", resp.data.data.user);
              });
            setLocalStorage("token", response.data.token);
            resolve({
              status: response.data.status,
              token: response.data.token,
              refreshToken: response.data.refreshToken,
            });
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function authenticate(company, refreshToken) {
  return new Promise((resolve, reject) => {
    apiGateway
      .post(`/auth/v1/authenticate?company=${company}`, { refreshToken })
      .then((response) => {
        multipartApiGateway.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data.token}`;
        if (!apiGateway.defaults.headers.common["Authorization"]) {
          apiGateway
            .get(`/auth/v1/verify`, {
              headers: { authorization: `${response.data.token}` },
            })
            .then((resp) => {
              apiGateway.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${response.data.token}`;
              setLocalStorage("user", resp.data.data.user);
              setLocalStorage("id", resp.data.data.user.userId);
            });
        }
        setLocalStorage("token", response.data.token);
        resolve({
          status: response.data.status,
          token: response.data.token,
          refreshToken: response.data.refreshToken,
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function SaveEvent(email, eventName, properties) {
  //TODO Adicionar rota de eventos
}

export async function postCookies(cookies, userID) {
  let body = { cookies };
  return new Promise((resolve, reject) => {
    cookiesWorkflow
      .patch(`users/${userID}`, body)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const connectLinkedin = async (cookies, token) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const payload = { cookies };
  const url = "https://services.driva.io/sales/v1/linkedin/login-ext";

  const response = await axios.post(url, payload, config);
};

export const linkedinEnrichment = async (linkedinURL) => {
  const config = {headers: { 'accept': 'application/json', 'Content-Type': 'application/json'}};
  const payload = linkedinURL;
  const url = "https://prod.services.driva.io/linkedin-api/v1/full_enrichment?call_source=extension";

  const response = await axios.post(url, payload, config);
}

export const getEmailsSnovio = async (domainUrl) => {
  const config = {headers: {"X-API-Key": "86636b0c-7b37-48eb-b57e-4da42393d2a0"}};
  const url = `https://services.driva.io/new-enrichment/v1/emails?domains=${domainUrl}`;

  const response = await axios.get(url, config);

  return response.data.data;
}

export const getWorkspaces = async (userId) => {
  const url = `https://services.driva.io/sales/v1/workspaces/user/${userId}?users=true`;

  const response = await apiGateway.get(url);

  return response;
}

export const getCurrentUserAssistant = async () => {
  const url = `https://services.driva.io/sales/v1/auth/current-user`;

  const response = await apiGateway.get(url);

  return response;
}

export const getListsAssistant = async (workspaceId) => {
  const url = `https://services.driva.io/sales/v1/lists/workspace/${workspaceId}`;

  const response = await apiGateway.get(url);

  return response;
}

export const createContactsAssistant = async (workspaceId, contacts, listId) => {
  const url = "https://services.driva.io/sales/v1/lists/extension"
  const payload = {
    "listId": `${listId}`,
    "workspaceId": `${workspaceId}`,
    "contacts": contacts
  }
  
  const response = await apiGateway.post(url, payload);

  return response;
}



