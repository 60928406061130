import React from "react";
import { Divider, Grid, Typography } from "@mui/material";
import notInfo from "../../../icons/notInfo.svg";
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';

export function Extras(props){
  const {language} = props;
  return (
    <React.Fragment>
      {props.responseCompany.possui_site ? (
        <Grid 
          container 
          flexDirection="column"
          style={{
            padding: "31px 16px 0px 16px"
          }}
        >
          <Grid item>
            <Grid flexDirection="row" alignItems="center" display="flex">
              <ShareOutlinedIcon/>
              <Typography 
                style={{
                  marginLeft: "8px", 
                  fontSize: "16px", 
                  fontFamily: "Inter", 
                  fontWeight: 600, 
                  color: "#1D2939"
                }}
              >
                {language === "pt-BR" ? "Tecnologias do site" : "Website technologies"}
              </Typography>
            </Grid>
          </Grid>
          {props.responseCompany.sites[0]?.tecnologias?.map((tecnologia, index) => (
            <Grid item key={`tecnologias-${index}`}>
              <Typography 
                style={{
                  fontSize: "14px", 
                  color: "#2F2F2F", 
                  fontWeight: 500, 
                  textTransform: "capitalize", 
                  fontFamily: "Inter",
                  marginTop: "24px",
                }}
              >
                {tecnologia.toLowerCase()}
              </Typography>
              <Divider style={{marginTop: "16px"}}/>
            </Grid>
          ))}
          
        </Grid>

      ) : (

        <Grid
          container 
          flexDirection="column" 
          flexWrap="nowrap" 
          style={{
            padding: "31px 58px 0px 58px"
          }}
          alignItems="center"
        >
          <Grid item>
            <img src={notInfo} width="100px"/>
          </Grid>
          <Grid item>
            <Typography
              style={{
                fontSize: "20px",
                fontWeight: 600,
                color: "#101828",
                fontFamily: "Inter",
                textAlign: "center",
                marginTop: "18px",
              }}
            >
              {language === "pt-BR" ? "Essa empresa não possui informações extras" : "This company doesn't have any extra information"}
            </Typography>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}