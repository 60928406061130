import { AppBar, Divider, Grid, IconButton, Tooltip } from "@mui/material";
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import SettingsIcon from '@mui/icons-material/Settings';
import React, { useState } from "react";
import logo from "../../components/icons/logo.svg";
import MaisOpçoes from "../modules/Opçoes";

export default function Header(props) {
  const {language} = props;
  const [openOpcoes, setOpenOpcoes] = useState(false);

  function Close(){
    window.parent.postMessage({
      event: "closeExtension"
    }, "*");
  };

  return(
    <AppBar
      position="fixed"
      sx={{
        top: 0,
        backgroundColor: "#FFFFFF",
        boxShadow: "none",
        zIndex: "200",
      }}
    >
      <Grid 
        container 
        justifyContent="space-between" 
        alignItems="center" 
        marginTop="5px"
        marginBottom="5px"
      > 
        <Grid item>
          <img
            style={{
              width: "111px",
              height: "20px",
            }}
            src={logo}
            alt="logo"
          />
        </Grid>

        <Grid item marginRight="5px">
          <Tooltip 
            title={
              language === "pt-BR" ? 
              "Configurações" : "Settings"
            }
          >
            <IconButton
              style={{color: "#475467"}}
              onClick={() => setOpenOpcoes(true)}
            >
              <SettingsIcon/>
            </IconButton>
          </Tooltip>
          <IconButton
            style={{color: "#475467"}}
            onClick={() => Close()}
          >
            <HorizontalRuleIcon/>
          </IconButton>
        </Grid>
      </Grid>
      <Divider/>
      {openOpcoes && 
        <MaisOpçoes 
          language={language} 
          open={openOpcoes} 
          handleClose={() => setOpenOpcoes(false)}
        />
      }
    </AppBar>
    
  
  );
}