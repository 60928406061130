import React, { useEffect, useState } from "react";
import CompanyHeader from "../../common/CompanyHeader";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Checkbox, CircularProgress, Divider, Grid, Paper, Snackbar, Typography } from "@mui/material";
import { createContactsAssistant, getCurrentUserAssistant, getEmailsSnovio, getListsAssistant, getWorkspaces } from "../../../services/queryService";
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import DrivaCircularProgress from "../../common/CircularProgress";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NotFoundInfo from "../../common/NotFoundInfo";

export default function Contacts(props) {
  const { language, responseCompany, url, linkedin } = props;
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCreateContacts, setLoadingCreateContacts] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [workspaces, setWorkspaces] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState({});
  const [lists, setLists] = useState([]);
  const [selectedList, setSelectedList] = useState({});
  const [openCreateList, setOpenCreateList] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [expanded, setExpanded] = React.useState('panel1');

  async function getEmails(url) {
    setLoading(true);
    if (linkedin == 0) {
      const urlSliced = url.slice(4);
      const response = await getEmailsSnovio(urlSliced);
      setEmails(response.filter((r) => r.nome !== " "));
    } else if (responseCompany.possui_site) {
      const response = await getEmailsSnovio(responseCompany.sites[0].site);
      setEmails(response.filter((r) => r.nome !== " "));
    }
    getFunctionWorkspaces();
  }

  async function getFunctionWorkspaces() {
    const assistantUser = await getCurrentUserAssistant();
    const response = await getWorkspaces(assistantUser.data.id);
    setWorkspaces(response.data.workspaces);
    console.log(response.data.workspaces)
    setLoading(false);
  }

  async function getWorkspacesLists(workspace) {
    const response = await getListsAssistant(workspace.id);
    console.log(response);
    setLists(response.data);
  }

  function handleChange(contact) {
    let arr = [];
    setSelectAll(false);
    if (selectedContacts.some((e) => e.email_info.email === contact.email_info.email)) {
      arr = selectedContacts.filter(
        (item) => item.email_info.email !== contact.email_info.email
      );
      setSelectedContacts(arr);
    } else {
      arr = [...new Set([...selectedContacts, contact])];
      setSelectedContacts([...new Set([...selectedContacts, contact])]);
    }
    if (arr.length > 0) {
      setOpenCreateList(true);
    } else {
      setOpenCreateList(false);
    }
  };

  function handleChangeSelectAll(all) {
    let arr = []
    setSelectAll(all);
    if (all) {
      arr = emails;
      setSelectedContacts(arr);
    } else {
      setSelectedContacts(arr);
    }
    if (arr.length > 0) {
      setOpenCreateList(true);
    } else {
      setOpenCreateList(false);
    }
  }

  function handleButtonWorkspace(workspace) {
    setSelectedWorkspace(workspace);
    getWorkspacesLists(workspace);
    setExpanded("panel2");
  }

  function handleButtonLists(list) {
    setExpanded("");
    setSelectedList(list);
  };

  async function handleButtonCreateContacts() {
    setLoadingCreateContacts(true);
    await createContactsAssistant(selectedWorkspace.id, selectedContacts, selectedList.id);
    setOpenToast(true);
    setLoadingCreateContacts(false);
    setOpenCreateList(false);
  };

  const handleChangePanel = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    getEmails(url);
  }, [url]);

  return (
    <React.Fragment>
      {loading ? (
        <DrivaCircularProgress language={language} />
      ) : (
        <>
          {emails.length > 0 ? (
            <Box>
              <CompanyHeader
                responseCompany={responseCompany}
                language={language}
                url={url}
                contactsHeader={true}
                linkedin={linkedin}
              />
              <Divider />
              <Box marginLeft="16px">
                <Grid container direction="row" alignItems="center" marginTop="14px">
                  <PeopleOutlineIcon fontSize="small" />
                  <Typography
                    marginLeft="8px"
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="500"
                  >
                    {language === "pt-BR" ? "Contatos" : "Contacts"}
                  </Typography>
                </Grid>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  flexWrap="nowrap"
                  marginTop="14px"
                  marginBottom="14px"
                  marginLeft="4px"
                >
                  <Checkbox
                    size="small"
                    sx={{ '&.Mui-checked': { color: "#F07F2D" } }}
                    onClick={() => { handleChangeSelectAll(!selectAll) }}
                    checked={selectAll}
                  />
                  <Typography fontSize="14px" fontFamily="Inter" fontWeight="600">
                    Selecionar tudo
                  </Typography>
                </Grid>
                <Divider />

                {emails?.map((e, index) => {
                  return (
                    <Box key={`emails-${index}`}>
                      <Grid
                        container
                        direction="row"
                        flexWrap="nowrap"
                        marginTop="14px"
                        marginBottom="14px"
                        marginLeft="4px"
                      >
                        <Checkbox
                          size="small"
                          sx={{ '&.Mui-checked': { color: "#F07F2D" } }}
                          onClick={() => handleChange(e)}
                          checked={selectedContacts.some((s) => s.email_info.email === e.email_info.email)}
                        />
                        <Grid container direction="column">
                          <Typography fontSize="14px" fontFamily="Inter" fontWeight="500">
                            {e.nome}
                          </Typography>
                          <Typography fontSize="14px" fontFamily="Inter" color="#838383">
                            {e.cargo}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                    </Box>
                  )
                })}
              </Box>
              {openCreateList && (
                <Grid
                  item
                  xs={12}
                  style={{ position: "fixed", bottom: "57px", width: "100%" }}
                >
                  <Paper
                    elevation={5}
                    style={{
                      padding: "15px 20px 0px 20px",
                      maxHeight: "500px",
                      overflowY: "auto",
                      height: "200px",
                      backgroundColor: "#F0F2F4"
                    }}
                  >
                    <Grid container direction="column" spacing="10px">
                      <Grid item>
                        <Typography fontFamily="Inter" fontSize="14px" fontWeight="500">
                          {language === "pt-BR" ? "Adicione contatos ao Sales Assistant" : "Add contacts to Sales Assistant"}
                        </Typography>
                      </Grid>
                      <Grid item style={{ marginTop: "6px" }}>
                        {/* <Autocomplete
                          options={workspaces}
                          getOptionLabel={(option) => option.name}
                          size="small"
                          loading
                          renderInput={(params) => 
                            <TextField 
                              {...params} 
                              label="Workspaces" 
                              placeholder="Selecione um Workspace"
                            />
                          }
                        /> */}
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChangePanel('panel1')}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                            style={{
                              fontSize: "14px",
                              fontWeight: "500"
                            }}
                          >
                            {Object.keys(selectedWorkspace).length != 0 ? selectedWorkspace.name : language === "pt-BR" ? "Selecione um workspace" : "Select a workspace"}
                          </AccordionSummary>
                          <AccordionDetails>
                            {workspaces?.map((w, index) => {
                              return (
                                <Button
                                  fullWidth
                                  style={{
                                    justifyContent: "flex-start",
                                    color: "#F07F2D",
                                    fontFamily: "Inter",
                                    fontSize: "14px"
                                  }}
                                  key={`workspaces-${index}`}
                                  onClick={() => handleButtonWorkspace(w)}
                                >
                                  {w.name}
                                </Button>
                              )
                            })}
                          </AccordionDetails>
                        </Accordion>
                      </Grid>

                      <Grid item>
                        <Accordion expanded={expanded === 'panel2'} onChange={handleChangePanel('panel2')}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2d-content"
                            id="panel2d-header"
                            style={{
                              fontSize: "14px",
                              fontWeight: "500"
                            }}
                            disabled={lists.length > 0 ? false : true}
                          >
                            {Object.keys(selectedList).length != 0 ? selectedList.name : language === "pt-BR" ? "Selecione uma lista" : "Select a list"}
                          </AccordionSummary>
                          <AccordionDetails>
                            {lists?.map((l, index) => {
                              return (
                                <Button
                                  fullWidth
                                  style={{
                                    justifyContent: "flex-start",
                                    color: "#F07F2D",
                                    fontFamily: "Inter",
                                    fontSize: "14px"
                                  }}
                                  key={`lists-${index}`}
                                  onClick={() => handleButtonLists(l)}
                                >
                                  {l.name}
                                </Button>
                              )
                            })}
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Button
                            variant="outlined"
                            style={{
                              color: "#7E93AA",
                              borderColor: "#7E93AA",
                              fontFamily: "Inter",
                              fontWeight: "600",
                              fontSize: "14px",
                              height: "34px",
                              textTransform: 'none',
                            }}
                            onClick={() => setOpenCreateList(false)}
                          >
                            {language === "pt-BR" ? "Cancelar" : "Cancel"}
                          </Button>
                          <Button
                            variant="contained"
                            disabled={loadingCreateContacts || Object.keys(selectedWorkspace).length == 0 || selectedContacts.length == 0 || Object.keys(selectedList).length == 0}
                            onClick={() => handleButtonCreateContacts()}
                            style={{
                              backgroundColor: "#F07F2D",
                              fontFamily: "Inter",
                              fontWeight: "600",
                              fontSize: "14px",
                              height: "34px",
                              width: "63%",
                              marginLeft: "4px",
                              textTransform: 'none',
                            }}
                          >
                            {loadingCreateContacts ? (
                              <CircularProgress
                                size={24}
                                sx={{ color: "white" }}
                              />
                            ) : (
                              <>
                                {language === "pt-BR" ? "Adicionar" : "Add"}
                              </>
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              )}
              <Snackbar
                open={openToast}
                autoHideDuration={2000}
                onClose={() => setOpenToast(false)}
                sx={{ top: "8%", bottom: "auto" }}
              >
                <Alert
                  onClose={() => setOpenToast(false)}
                  severity="success"
                  variant="filled"
                  sx={{ width: "100%" }}
                >
                  {language === "pt-BR" ? "Lista criada com sucesso" : "List created successfully"}
                </Alert>
              </Snackbar>
            </Box>
          ) : (
            <NotFoundInfo language={language} />
          )}
        </>
      )}
    </React.Fragment>
  )
}