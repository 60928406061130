import React from "react";
import "./index.css";
import App from "./App";
import { createRoot } from "react-dom/client";

let refreshToken = "";
let userCompany = "";
let page = "";
let company = "";
let textLinkedin = "";
let language = "";
let urlHref = "";
let urlPathName = "";
 
const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

window.addEventListener("message", (e) => {
  let urlTrusted = window.document.location.search.slice(5);
  let trustedOrigins = [`https://${urlTrusted}`, `http://${urlTrusted}`]
  if (e.data.event === "getLogin" && trustedOrigins.includes(e.origin)) {
    refreshToken = e.data.refreshToken;
    userCompany = e.data.userCompany;
    urlHref = e.data.urlHref;
    urlPathName = e.data.urlPathName;
    page = e.data.page;
    company = e.data.company;
    textLinkedin = e.data.linkedin;
    language = e.data.language;

    const domNode = document.getElementById('root');
    const root = createRoot(domNode);
    root.render(
      <App
        refreshToken={refreshToken}
        userCompany={userCompany}
        page={page}
        company={company}
        url={params.url}
        urlHref={urlHref}
        urlPathName={urlPathName}
        linkedin={textLinkedin}
        language={language}
      />
    );
  }
}, false);

if (window.location === window.parent.location) {
  const domNode = document.getElementById('root');
  const root = createRoot(domNode);
  root.render(
    <App
      loginScreen
      url={params.url}
    />
  );
};
