const setLocalStorage = (key, obj) => {
  localStorage.setItem(key, JSON.stringify(obj));
};

const setLocalStorageChecked = (key, obj) => {
  localStorage.setItem(key, obj);
};

const getLocalStorageChecked = (key) => {
  let data = localStorage.getItem(key);
  if (data === null) return null;
  return data;
};

const getLocalStorage = (key) => {
  let data = localStorage.getItem(key);
  if (data === null) return null;
  return {
    data: JSON.parse(data),
  };
};

export { setLocalStorage, getLocalStorage, setLocalStorageChecked, getLocalStorageChecked };
