import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import {Grid, Toolbar } from "@mui/material";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import DadosDaEmpresa from "../Descubra/components/DadosDaEmpresa";
import { Contatos } from "../Descubra/components/Contatos";
import { Extras } from "../Descubra/components/Extras";
import CompanyHeader from "../../common/CompanyHeader";

export default function MoreInfoSearch(props) {
  const { Transition, handleClose, open, data, language } = props;
  const [dados, setDados] = React.useState(true);
  const [contatos, setContatos] = React.useState(false);
  const [extras, setExtras] = React.useState(false);

  const clearState = (param) => {
    switch (param) {
      case "dados":
        setContatos(false);
        setExtras(false);
        break;
      case "contatos":
        setDados(false);
        setExtras(false);
        break;
      case "extras":
        setDados(false);
        setContatos(false);
        break;
      default:
        setDados(false);
        setContatos(false);
        setExtras(false);
        break;
    }
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Grid
          sx={{
            top: 0,
            backgroundColor: "#FFFFFF",
            boxShadow: "none",
          }}
        >
          <Button
            style={{
              justifyContent: "flex-start"
            }}
            onClick={handleClose}
            disableRipple
            startIcon={
              <ArrowBackIosIcon
                style={{ paddingLeft: "10px", fontSize: "14px", color: "#FF7A00" }}
              />
            }
          >
            <Typography
              style={{
                fontFamily: "Inter",
                textTransform: "capitalize",
                alignItems: "center",
                display: "flex",
                fontSize: "16px",
                fontWeight: "500",
                color: "#FF7A00",
              }}
            >
              {language === "pt-BR" ? "Voltar" : "Back"} 
            </Typography>
          </Button>
          <Divider />
          
          <Grid style={{backgroundColor: "white"}}>
            <CompanyHeader language={language} responseCompany={data}/>
            <Grid>
              <Toolbar
                sx={{
                  minHeight: "0px",
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: "white"
                }}
              >
                <Button
                  disableRipple={true}
                  style={
                    dados
                      ? {
                          color: "#F07F2D",
                          fontSize: "14px",
                          fontWeight: 500,
                          borderBottom: "3px solid #F07F2D",
                          borderRadius: "0px",
                          fontFamily: "Inter",
                          textTransform: "none",
                        }
                      : {
                          color: "#475467",
                          fontSize: "14px",
                          fontWeight: 500,
                          fontFamily: "Inter",
                          textTransform: "none",
                        }
                  }
                  onClick={() => {
                    setDados(true);
                    clearState("dados");
                  }}
                >
                  {language === "pt-BR" ? "Sobre" : "About"} 
                </Button>
                <Button
                  disableRipple={true}
                  style={
                    contatos
                      ? {
                        color: "#F07F2D",
                        fontSize: "14px",
                        fontWeight: 500,
                        borderBottom: "3px solid #F07F2D",
                        borderRadius: "0px",
                        fontFamily: "Inter",
                        textTransform: "none",
                      }
                    : {
                        color: "#475467",
                        fontSize: "14px",
                        fontWeight: 500,
                        fontFamily: "Inter",
                        textTransform: "none",
                      }
                  }
                  onClick={() => {
                    setContatos(true);
                    clearState("contatos");
                  }}
                >
                  {language === "pt-BR" ? "Contatos" : "Contacts"} 
                </Button>
                <Button
                  disableRipple={true}
                  style={
                    extras
                      ? {
                          color: "#F07F2D",
                          fontSize: "14px",
                          fontWeight: 500,
                          borderBottom: "3px solid #F07F2D",
                          borderRadius: "0px",
                          fontFamily: "Inter",
                          textTransform: "none",
                        }
                      : {
                          color: "#475467",
                          fontSize: "14px",
                          fontWeight: 500,
                          fontFamily: "Inter",
                          textTransform: "none",
                        }
                  }
                  onClick={() => {
                    setExtras(true);
                    clearState("extras");
                  }}
                >
                  Extras
                </Button>
              </Toolbar>
            </Grid>
          </Grid>
        </Grid>
        {dados && <DadosDaEmpresa responseCompany={data} language={language}/>}
        {contatos && <Contatos responseCompany={data} language={language}/>}
        {extras && <Extras responseCompany={data} language={language}/>}
      </Dialog>
    </React.Fragment>
  );
}
