import axios from "axios";

export const client = axios.create({
  baseURL: "https://lidia-ts-3-0-uvk2pv2hja-uc.a.run.app",
});

export const spider = axios.create({
  baseURL: "https://spiderweb-uvk2pv2hja-ue.a.run.app",
});

export const drivaMobBackend = axios.create({
  baseURL: "http://localhost:3333",
});

export const apiGateway = axios.create({
  baseURL: "https://services.driva.io",
});

export const multipartApiGateway = axios.create({
  baseURL: "https://services.driva.io",
  headers: { "Content-Type": "multipart/form-data" },
});

export const cookiesWorkflow = axios.create({
  baseURL: "https://workflow-backend.dokku.driva.io/sales/v1/"
});

