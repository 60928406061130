import { Grid, Typography } from "@mui/material";
import notFound from "../../components/icons/notFound.svg";

export default function NotFoundInfo(props){
  const {language} = props;
  return(
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      flexWrap="nowrap"
      style={{ height: "700px", padding: "49px" }}
    >
      <Grid item>
        <img src={notFound}/>
      </Grid>
      <Grid item style={{marginTop: "17px"}}>
        <Typography style={{ color: "#101828", fontSize: "20px", fontFamily: "Inter", fontWeight: 600, textAlign: "center" }}>
          {language === "pt-BR" ? 
            "Não achamos informações disponíveis nesta página" : 
            "We can't find any information available on this page"
          }
        </Typography>
      </Grid>
      <Grid item style={{marginTop: "17px"}}>
        <Typography style={{ color: "#667085", fontSize: "16px", fontFamily: "Inter", textAlign: "center" }}>
          {language === "pt-BR" ? 
            "Tente acessar outros sites da empresa e faremos o nosso melhor para ajudá-lo com sua pesquisa." : 
            "Try going to other company websites and we will do our best to help you with your search."
          }
        </Typography>
      </Grid>
    </Grid>
  )
}