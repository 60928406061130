import React from "react";
export default function LinkButton(props) {
  const { Icon, url } = props;

  return (
    <React.Fragment>
      {url ? (
        <a
          href={`https://${url}`}
          target="__blank"
          rel="noopener"
        >
          {Icon}
        </a>

      ) : (
        <a>
          {Icon}
        </a>
      )}
    </React.Fragment>
  );
}
