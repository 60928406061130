import { Box } from "@material-ui/core";
import { Grid, Typography } from "@mui/material";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";

export default function DrivaCircularProgress(props) {
  const {language} = props;
  
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ height: "350px" }}
    >
      <Grid item>
        <Typography style={{ color: "#667085", fontSize: "16px", fontFamily: "Inter" }}>
          {language === "pt-BR" ? "Procurando informações" : "Searching for information"}
        </Typography>
      </Grid>
      <Grid item>
        <Box sx={{ position: "relative" }}>
          <CircularProgress
            variant="determinate"
            sx={{
              color: "#C4C4C4",
            }}
            size={50}
            thickness={4}
            {...props}
            value={100}
          />
          <CircularProgress
            variant="indeterminate"
            disableShrink
            sx={{
              color: "#003355",
              animationDuration: "1000ms",
              position: "absolute",
              left: 0,
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: "round",
              },
            }}
            size={50}
            thickness={4}
            {...props}
          />
        </Box>
      </Grid>
    </Grid>
    
  );
}
