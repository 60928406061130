import { Grid, Typography } from "@mui/material";
import notFoundFavicon from "../icons/notFoundFavicon.svg";
import LinkButton from "./Linkbutton";

import LanguageIcon from "@mui/icons-material/Language";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";

export default function CompanyHeader(props){
  const {linkedin, language, url, responseCompany, contactsHeader} = props;

  return(

    <Grid
      container 
      alignItems="center" 
      flexWrap="nowrap"
      direction="row"
      padding={contactsHeader ? "60px 0px 0px 30px" : "80px 0px 0px 30px"}
    >
      <Grid item marginRight="20px">
        {linkedin==0 || linkedin == undefined ? (
          <img
            style={{
              width: `${contactsHeader ? "20px" : "40px"}`,
              height: `${contactsHeader ? "20px" : "40px"}`,
            }}
            src={`https://www.google.com/s2/favicons?domain=${url?.replace(
              /^(www[1-9]?\.)/,
              ""
            )}` ? `https://www.google.com/s2/favicons?domain=${url?.replace(
              /^(www[1-9]?\.)/,
              ""
            )}` : notFoundFavicon}
            alt="Avatar"
          />

        ) : (
          <img
            style={{
              width: `${contactsHeader ? "20px" : "40px"}`,
              height: `${contactsHeader ? "20px" : "40px"}`,
            }}
            src={notFoundFavicon}
            alt="Avatar"
          />
        )}
      </Grid>
      <Grid item marginBottom="14px">
        <Grid marginBottom="4px">
          <Typography 
            style={{
              fontSize: "12px", 
              fontFamily: "Inter", 
              color: "#475467"
            }}
          >
            {language === "pt-BR" ? "Nome fantasia" : "Trading name"}
          </Typography>
          <Typography 
            style={{ 
              fontWeight: 600, 
              fontSize: `${contactsHeader ? "14px" : "16px"}`, 
              fontFamily: "Inter", 
              color: "#1D2939" 
            }}
          >
            {responseCompany?.nome_fantasia
              ? responseCompany?.nome_fantasia
              : responseCompany?.razao_social}
          </Typography>
        </Grid>
        {!contactsHeader && (
          <Grid>
            <LinkButton
              url={
                (responseCompany?.possui_site && responseCompany?.sites?.length > 0)
                ? responseCompany?.sites[0]?.site
                : undefined
              }
              Icon={
                <LanguageIcon
                  style={
                    (responseCompany?.possui_site && responseCompany?.sites?.length > 0)
                      ? { color: "#003355", paddingRight: "5px" }
                      : { color: "#EAECF5", paddingRight: "5px" }
                  }
                />
              }
            />
            <LinkButton
              url={
                (responseCompany?.possui_linkedin && responseCompany?.linkedin?.length > 0)
                  ? responseCompany?.linkedin[0]?.url
                  : undefined
              }
              Icon={
                <LinkedInIcon
                  style={
                    (responseCompany?.possui_linkedin && responseCompany?.linkedin?.length > 0)
                      ? { color: "#003355", paddingRight: "5px" }
                      : { color: "#EAECF5", paddingRight: "5px" }
                  }
                />
              }
            />
            <LinkButton
              url={
                (responseCompany?.possui_instagram && responseCompany?.instagram?.length > 0)
                  ? responseCompany?.instagram[0]?.url
                  : undefined
              }
              Icon={
                <InstagramIcon
                  style={
                    (responseCompany?.possui_instagram && responseCompany?.instagram?.length > 0)
                      ? { color: "#003355", paddingRight: "5px" }
                      : { color: "#EAECF5", paddingRight: "5px" }
                  }
                />
              }
            />
            <LinkButton
              url={
                (responseCompany?.possui_twitter && responseCompany?.twitter?.length > 0)
                  ? responseCompany?.twitter[0]?.url
                  : undefined
              }
              Icon={
                <TwitterIcon
                  style={
                    (responseCompany?.possui_twitter && responseCompany?.twitter?.length > 0)
                      ? { color: "#003355", paddingRight: "5px" }
                      : { color: "#EAECF5", paddingRight: "5px" }
                  }
                />
              }
            />
                  
            <LinkButton
              url={
                (responseCompany?.possui_facebook && responseCompany?.facebook?.length > 0)
                  ? responseCompany?.facebook[0]?.url
                  : undefined
              }
              Icon={
                <FacebookIcon
                  style={
                    (responseCompany?.possui_facebook && responseCompany?.facebook?.length > 0)
                      ? { color: "#003355", paddingRight: "5px" }
                      : { color: "#EAECF5", paddingRight: "5px" }
                  }
                />
              }
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}