import {
  Button,
  Grid,
  Typography,
  Divider,
  Tooltip,
  Chip,
  ButtonBase,
} from "@mui/material";
import React, { useEffect } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';

export default function DadosDaEmpresa(props) {
  const {language} = props;
  useEffect(() => {}, [props?.responseCompany]);

  function ClickToCopy(props) {
    const { item } = props;
    return (
      <Tooltip title="Clique para Copiar">
        <ButtonBase
          onClick={() => navigator.clipboard.writeText(`${item}`)}
          style={{textAlign: "left"}}
        >
          <Grid container>
            <Typography
              style={{
                fontSize: "12px",
                color: "#1D2939",
                fontFamily: "Inter",
                fontWeight: 500,
                cursor: "pointer",
              }}
            >
              {item}
            </Typography>
          </Grid>
        </ButtonBase>
      </Tooltip>
    );
  }

  function ClickToCopyEndereco(props) {
    const { item } = props;
    return (
      <Tooltip title="Clique para Copiar">
        <ButtonBase
          onClick={() => navigator.clipboard.writeText(`${item}`)}
          style={{textAlign: "left"}}
        >
          <Grid container>
            <Typography
              style={{
                fontSize: "12px",
                color: "#1D2939",
                fontFamily: "Inter",
                fontWeight: 500,
                cursor: "pointer",
              }}
            >
              {item}
            </Typography>
            <Button
              style={{
                fontSize: "12px",
                color: "#1D2939",
                fontFamily: "Inter",
                fontWeight: 500,
                cursor: "pointer",
                marginLeft: "-5px",
                backgroundColor: "#EAECF5"
              }}
              target="_blank"
              href={`https://www.google.com.br/maps/place/${item.replace(" " , "+")},+${props.responseCompany?.microrregiao.replace(" ", "+")}`}
              
            >
              {language === "pt-BR" ? "Ver no GPS" : "View on GPS"} <PlaceOutlinedIcon style={{fontSize: "20px", marginLeft: "5px"}}/>
            </Button>
          </Grid>
        </ButtonBase>
      </Tooltip>
    );
  }

  return (
    <React.Fragment>
      <Grid style={{padding: "30px 18px 32px 18px"}}>
        <Grid container flexDirection="row" flexWrap="nowrap">
          <InfoOutlinedIcon/>
          <Typography 
            style={{
              marginLeft: "8px", 
              fontSize: "16px", 
              fontFamily: "Inter", 
              fontWeight: 600, 
              color: "#1D2939"
            }}
          >
            {language === "pt-BR" ? "Dados cadastrais" : "Registration"}
          </Typography>
        </Grid>
        <Grid container flexDirection="row" style={{marginTop: "16px"}}> 
          {props.responseCompany.situacao_cadastral &&(
            <Chip 
              label={props.responseCompany.situacao_cadastral} 
              style={{
                fontSize: "10px", 
                fontFamily: "Inter", 
                fontWeight: 500, 
                color: "#475467",
                marginRight: "12px",
              }}
            />
          )}
          {props.responseCompany.empresa_publico_privada &&(
            <Chip 
              label={props.responseCompany.empresa_publico_privada} 
              style={{
                fontSize: "10px", 
                fontFamily: "Inter", 
                fontWeight: 500, 
                color: "#475467",
                marginRight: "12px",
              }}
            />
          )}
          {props.responseCompany.qtde_filiais > 0 ? (
            <Chip
              label={props.responseCompany.qtde_filiais + " FILIAIS"}
              style={{
                fontSize: "10px", 
                fontFamily: "Inter", 
                fontWeight: 500, 
                color: "#475467"
              }}
            />
          ) : props.responseCompany.qtde_filiais == 0 ? (
            <Chip
              label= "0 FILIAIS"
              style={{
                fontSize: "10px", 
                fontFamily: "Inter", 
                fontWeight: 500, 
                color: "#475467"
              }}
            />
          ) : undefined}
        </Grid>
        <Grid style={{marginTop: "18px"}}>
          <Typography
            style={{
              fontSize: "12px",
              color: "#475467",
              fontFamily: "Inter",
            }}
          >
            {language === "pt-BR" ? "Razão social" : "Company name"}
          </Typography>
          <ClickToCopy
            item={
              props.responseCompany.razao_social
                ? props.responseCompany.razao_social
                : "Não consta"
            }
          />
        </Grid>
        <Grid container flexDirection="row" flexWrap="nowrap">
          <Grid container flexDirection="column">
            <Grid style={{marginTop: "18px"}}>
              <Typography
                style={{
                  fontSize: "12px",
                  color: "#475467",
                  fontFamily: "Inter",
                }}
              >
                CNPJ
              </Typography>
              <ClickToCopy
                item={
                  props.responseCompany.cnpj
                    ? props.responseCompany.cnpj
                    : "Não consta"
                }
              />
            </Grid>
          </Grid>
          <Grid container flexDirection="column">
            <Grid style={{marginTop: "18px"}}>
              <Typography
                style={{
                  fontSize: "12px",
                  color: "#475467",
                  fontFamily: "Inter",
                }}
              >
                {language === "pt-BR" ? "Data de abertura" : "Opening date"}
              </Typography>
              <ClickToCopy
                item={
                  props.responseCompany.data_inicio_atividade
                    ? props.responseCompany.data_inicio_atividade
                    : "Não consta"
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container flexDirection="row" flexWrap="nowrap">
          <Grid container flexDirection="column">
            <Grid style={{marginTop: "18px"}}>
              <Typography
                style={{
                  fontSize: "12px",
                  color: "#475467",
                  fontFamily: "Inter",
                }}
              >
                {language === "pt-BR" ? "Simples?" : "Simple?"}
              </Typography>
              <ClickToCopy
                item={
                  props.responseCompany.opcao_pelo_simples
                    ? "SIM"
                    : "NÃO"
                }
              />
            </Grid>
          </Grid>
          <Grid container flexDirection="column">
            <Grid style={{marginTop: "18px"}}>
              <Typography
                style={{
                  fontSize: "12px",
                  color: "#475467",
                  fontFamily: "Inter",
                }}
              >
                MEI
              </Typography>
              <ClickToCopy
                item={
                  props.responseCompany.opcao_pelo_mei
                    ? "SIM"
                    : "NÃO"
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Divider/>

      <Grid style={{padding: "30px 18px 32px 18px"}}>
        <Grid container flexDirection="row" flexWrap="nowrap">
          <WorkOutlineIcon/>
          <Typography 
            style={{
              marginLeft: "8px", 
              fontSize: "16px", 
              fontFamily: "Inter", 
              fontWeight: 600, 
              color: "#1D2939"
            }}
          >
            {language === "pt-BR" ? "Segmento" : "Segment"}
          </Typography>
        </Grid>
        <Grid container flexDirection="row" flexWrap="nowrap">
          <Grid container flexDirection="column">
            <Grid style={{marginTop: "18px"}}>
              <Typography
                style={{
                  fontSize: "12px",
                  color: "#475467",
                  fontFamily: "Inter",
                }}
              >
                {language === "pt-BR" ? "Segmento" : "Segment"}
              </Typography>
              <ClickToCopy
                item={
                  props.responseCompany.segmento
                    ? props.responseCompany.segmento
                    : "Não consta"
                }
              />
            </Grid>
          </Grid>
          <Grid container flexDirection="column">
            <Grid style={{marginTop: "18px"}}>
              <Typography
                style={{
                  fontSize: "12px",
                  color: "#475467",
                  fontFamily: "Inter",
                }}
              >
                {language === "pt-BR" ? "Ramos de atividade" : "Fields of activity"}
              </Typography>
              <ClickToCopy
                item={
                  props.responseCompany.ramo_de_atividade
                    ? props.responseCompany.ramo_de_atividade
                    : "Não consta"
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid style={{marginTop: "18px"}}>
          <Typography
            style={{
              fontSize: "12px",
              color: "#475467",
              fontFamily: "Inter",
            }}
          >
            CNAE {language === "pt-BR" ? "principal subclasse" : "main subclass"}
          </Typography>
          <ClickToCopy
            item={
              props.responseCompany.cnae_principal_desc_subclasse
                ? props.responseCompany.cnae_principal_desc_subclasse
                : "Não consta"
            }
          />
        </Grid>
      </Grid>

      <Divider/>

      <Grid style={{padding: "30px 18px 32px 18px"}}>
        <Grid container flexDirection="row" flexWrap="nowrap">
          <PlaceOutlinedIcon/>
          <Typography 
            style={{
              marginLeft: "8px", 
              fontSize: "16px", 
              fontFamily: "Inter", 
              fontWeight: 600, 
              color: "#1D2939"
            }}
          >
            {language === "pt-BR" ? "Endereço" : "Address"}
          </Typography>
        </Grid>
        <Grid container flexDirection="row" flexWrap="nowrap">
          <Grid container flexDirection="column">
            <Grid style={{marginTop: "18px"}}>
              <Typography
                style={{
                  fontSize: "12px",
                  color: "#475467",
                  fontFamily: "Inter",
                }}
              >
                {language === "pt-BR" ? "Município" : "City"}
              </Typography>
              <ClickToCopy
                item={
                  props.responseCompany.microrregiao
                    ? props.responseCompany.microrregiao
                    : "Não consta"
                }
              />
            </Grid>
          </Grid>
          <Grid container flexDirection="column">
            <Grid style={{marginTop: "18px"}}>
              <Typography
                style={{
                  fontSize: "12px",
                  color: "#475467",
                  fontFamily: "Inter",
                }}
              >
                {language === "pt-BR" ? "Estado" : "State"}
              </Typography>
              <ClickToCopy
                item={
                  props.responseCompany.sigla_uf
                    ? props.responseCompany.sigla_uf
                    : "Não consta"
                }
              />
            </Grid>
          </Grid>
          <Grid container flexDirection="column">
            <Grid style={{marginTop: "18px"}}>
              <Typography
                style={{
                  fontSize: "12px",
                  color: "#475467",
                  fontFamily: "Inter",
                }}
              >
                CEP
              </Typography>
              <ClickToCopy
                item={
                  props.responseCompany.cep
                    ? props.responseCompany.cep
                    : "Não consta"
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid style={{marginTop: "18px"}}>
          <Typography
            style={{
              fontSize: "12px",
              color: "#475467",
              fontFamily: "Inter",
            }}
          >
            {language === "pt-BR" ? "Endereço" : "Address"}
          </Typography>
          <Grid container flexDirection="row">
            <ClickToCopyEndereco
              item={
                props.responseCompany.endereco
                  ? props.responseCompany.endereco
                  : "Não consta"
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Divider/>

      <Grid style={{padding: "30px 18px 100px 18px"}}>
        <Grid container flexDirection="row" flexWrap="nowrap">
          <AttachMoneyIcon/>
          <Typography 
            style={{
              marginLeft: "8px", 
              fontSize: "16px", 
              fontFamily: "Inter", 
              fontWeight: 600, 
              color: "#1D2939"
            }}
          >
            {language === "pt-BR" ? "Tamanho" : "Size"}
          </Typography>
        </Grid>
        <Grid container flexDirection="row" flexWrap="nowrap">
          <Grid container flexDirection="column">
            <Grid style={{marginTop: "18px"}}>
              <Typography
                style={{
                  fontSize: "12px",
                  color: "#475467",
                  fontFamily: "Inter",
                }}
              >
                {language === "pt-BR" ? "Faixa de faturamento" : "Billing range"}
              </Typography>
              <ClickToCopy
                item={
                  props.responseCompany.faixa_faturamento_grupo
                    ? props.responseCompany.faixa_faturamento_grupo
                    : "Não consta"
                }
              />
            </Grid>
          </Grid>
          <Grid container flexDirection="column">
            <Grid style={{marginTop: "18px"}}>
              <Typography
                style={{
                  fontSize: "12px",
                  color: "#475467",
                  fontFamily: "Inter",
                }}
              >
                {language === "pt-BR" ? "Faixa de funcionários" : "Employees range"}
              </Typography>
              <ClickToCopy
                item={
                  props.responseCompany.faixa_funcionarios_grupo
                    ? props.responseCompany.faixa_funcionarios_grupo
                    : "Não consta"
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid style={{marginTop: "18px"}}>
          <Grid container flexDirection="row" flexWrap="nowrap">
            <Grid container flexDirection="column">
              <Typography
                style={{
                  fontSize: "12px",
                  color: "#475467",
                  fontFamily: "Inter",
                }}
              >
                {language === "pt-BR" ? "Capital social" : "Capital stock"}
              </Typography>
              <Grid container flexDirection="row">
                <ClickToCopy
                  item={
                    props.responseCompany.capital_social
                      ? props.responseCompany.capital_social
                      : "Não consta"
                  }
                />
              </Grid>
            </Grid>
            <Grid container flexDirection="column">
              <Typography
                style={{
                  fontSize: "12px",
                  color: "#475467",
                  fontFamily: "Inter",
                }}
              >
                {language === "pt-BR" ? "Porte" : "Size"}
              </Typography>
              <Grid container flexDirection="row">
                <ClickToCopy
                  item={
                    props.responseCompany.porte
                      ? props.responseCompany.porte
                      : "Não consta"
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
