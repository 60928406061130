import React, { useEffect, useState } from "react";
import { Button, FormControl, Grid, InputAdornment, Typography, } from "@mui/material";
import Input from "@material-ui/core/Input";
import Slide from "@mui/material/Slide";

import SearchIcon from "@mui/icons-material/Search";
import searchSVG from "../../icons/search.svg";
import notFound from "../../../components/icons/notFound.svg";


import { multipartApiGateway } from "../../../services/axiosClients";
import MoreInfoSearch from "./MoreInfoSearch";
import { SaveEvent } from "../../../services/queryService";
import DrivaCircularProgress from "../../common/CircularProgress";
import Header from "../../common/Header";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

export class SearchRegex {
  static cnpj = /^\d{0,2}\.?\d{0,3}\.?\d{0,3}\/?\d{2,4}-?\d{2}$/;
  static dominio =
    /^(https?:\/\/)?[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+(\/(.*?))?$/i;
}

export function Search(props) {
  const {language} = props;
  const [inputValue, setInputValue] = useState("");
  const [response, setResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const [open, setOpen] = useState(false);

  function formatCNPJ(cnpj) {
    let val = String(cnpj).padStart(14, "0");
    return `${val.slice(0, 2)}.${val.slice(2, 5)}.${val.slice(5, 8)}/${val.slice(
      8,
      12
    )}-${val.slice(12)}`;
  };

  const handleClickOpen = (obj) => {
    setData(obj);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleSubmit();
    }
  };

  useEffect(() => {}, [response]);

  const handleSubmit = () => {
    let body = new FormData();
    body.append("size", "10");
    body.append("page", "0");

    SaveEvent("email", "Pesquisa Realizada", { searchTerm: inputValue });
    setLoading(true);
    setResponse([]);
    try {
      body.append(
        "query",
        `{"$source":"empresas_do_brasil", "$or": [
          {
              "razao_social.trigram": "${inputValue}"
          },
          {
              "nome_fantasia.trigram": "${inputValue}"
          },
          {
              "cnpj": "${inputValue.match(SearchRegex.cnpj) ? inputValue.match(/\d+/g).join("") : 0}"
          },
          {
            "sites.site": "${inputValue}"
          }
      ]}`
      );
      multipartApiGateway
        .post(`/storage_service/v1/search`, body, {params: { credits_type: "free" }})
        .then((resposta) => {
          if (resposta.data.length > 0) {
            setResponse(resposta.data);
            setLoading(false);
          } else {
            setResponse({ error: "Não foi possível encontrar a empresa" });
            setLoading(false);
          }
        })
        .catch((err) => {
          setResponse({ error: "Não foi possível encontrar a empresa" });
          setLoading(false);
        });
    } catch (err) {}
  };

  return (
    <React.Fragment>
      <Header/>
      <FormControl
        fullWidth
        style={{ 
          borderBottom: "none" ,
          paddingTop: "61px",
        }}
      >
        <Input
          style={{
            padding: "0px 0px 0px 8px",
            fontSize: "16px",
            height: "45px",
            backgroundColor: "white",
            border: "1px solid #667085",
            borderRadius: "4px",
            margin: "16px 16px 24px 16px",
            fontFamily: "Inter",
            fontWeight: "400",
            color: "black",
          }}
          id="search-input"
          disableUnderline
          placeholder={language === "pt-BR" ? "Pesquise por empresas" : "Search for companies"}
          value={inputValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          inputProps={{ maxLength: 100 }}
          startAdornment={
            <React.Fragment>
              <InputAdornment
                style={{ padding: "0px 6px 0px 6px" }}
                position="start"
                onClick={handleSubmit}
              >
                <SearchIcon style={{fontSize: "30px", color: "#F07F2D"}}/>
              </InputAdornment>
            </React.Fragment>
          }
        />
      </FormControl>
      <Grid style={{backgroundColor: "#FAFAFA", marginBottom: "100px"}}>
        {response?.length > 0 ? (
          <Grid>
            <Typography
              style={{
                color: "#838383",
                fontSize: "14px",
                paddingLeft: "16px",
                paddingTop: "24px",
                fontFamily: "Inter",
                fontWeight: "400",
              }}
            >
             ({response.length}) {language === "pt-BR" ? "resultados encontrados" : "results"}
            </Typography>
            {response?.map((item, index) => (
              <React.Fragment key={index}>
                <Grid 
                  style={{
                    backgroundColor: "white", 
                    borderRadius: "8px", 
                    border: "1px solid #EAECF5",
                    //width: "93%", 
                    margin: "12px 15px 0px 15px"
                  }}
                >
                  <Button
                    style={{
                      padding: "0px",
                      width: "100%",
                      justifyContent: "left",
                    }}
                    onClick={() => handleClickOpen(item)}
                  >
                    <Typography
                      style={{
                        padding: "16px 16px 0px 16px",
                        fontWeight: "500",
                        color: "#1D2939",
                        fontSize: "14px",
                        fontFamily: "Inter",
                      }}
                      align="left"
                      key={index.cnpj}
                    >
                      {item.nome_fantasia
                        ? item.nome_fantasia
                        : item.razao_social
                        ? item.razao_social
                        : item.domain}{" "}
                    </Typography>
                  </Button>
                  <Typography
                    style={{
                      padding: "0px 0px 0px 16px",
                      color: "#475467",
                      fontSize: "14px",
                      fontFamily: "Inter",
                    }}
                  >
                    CNPJ {formatCNPJ(item?.cnpj)}
                  </Typography>
                  <Grid
                    container
                    spacing={2}
                    style={{ padding: "0px 16px 16px 16px" }}
                  >
                    <Grid item>
                      {item.municipio && item.uf && (
                        <Button
                          style={{
                            fontSize: "12px",
                            padding: "2px 8px",
                            margin: "13px 8px 0px 0px",
                            backgroundColor: "#EAECF5",
                            color: "#475467",
                            cursor: "default",
                            fontFamily: "Inter",
                            fontWeight: "500",
                            borderRadius: "16px",
                          }}
                          disableRipple={true}
                        >
                          {item.municipio} - {item.sigla_uf}
                        </Button>
                      )}
                    
                      {item?.segmento && (
                        <Button
                          style={{
                            fontSize: "12px",
                            padding: "2px 8px",
                            margin: "13px 8px 0px 0px",
                            backgroundColor: "#EAECF5",
                            color: "#475467",
                            cursor: "default",
                            fontFamily: "Inter",
                            fontWeight: "500",
                            borderRadius: "16px",
                          }}
                          disableRipple={true}
                        >
                          {item.segmento}
                        </Button>
                      )}
                      {item?.matriz == true ?(
                        <Button
                          style={{
                            fontSize: "12px",
                            padding: "2px 8px",
                            margin: "13px 8px 0px 0px",
                            backgroundColor: "#EAECF5",
                            color: "#475467",
                            cursor: "default",
                            fontFamily: "Inter",
                            fontWeight: "500",
                            borderRadius: "16px",
                          }}
                          disableRipple={true}
                        >
                          MATRIZ
                        </Button>
                      ) : (
                        <Button
                          style={{
                            fontSize: "12px",
                            padding: "2px 8px",
                            margin: "13px 8px 0px 0px",
                            backgroundColor: "#EAECF5",
                            color: "#475467",
                            cursor: "default",
                            fontFamily: "Inter",
                            fontWeight: "500",
                            borderRadius: "16px",
                          }}
                          disableRipple={true}
                        >
                          FILIAL
                        </Button>
                      )}
                      {item.empresa_publico_privada && (
                        <Button
                          style={{
                            fontSize: "12px",
                            padding: "2px 8px",
                            margin: "13px 8px 0px 0px",
                            backgroundColor: "#EAECF5",
                            color: "#475467",
                            cursor: "default",
                            fontFamily: "Inter",
                            fontWeight: "500",
                            borderRadius: "16px",
                          }}
                          disableRipple={true}
                        >
                          {item.empresa_publico_privada}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </React.Fragment>
            ))}
            <div style={{ margin: "60px" }}></div>
          </Grid>
        ) : response?.error ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ height: "350px" }}
          >
            <Grid
              container
              item
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{
                padding: "50px"
              }}
            >
              <img src={notFound}/>
              <Typography
                style={{ 
                  textAlign: "center",
                  fontSize: "20px", 
                  fontFamily: "Inter", 
                  fontWeight: "600",
                  marginTop: "18px"
                }}
              >
                {language === "pt-BR" ? "Não achamos informações sobre essa pesquisa" : "We couldn't find any information about this research"}
              </Typography>
              <Typography
                style={{ 
                  fontSize: "16px", 
                  color: "#667085", 
                  fontFamily: "Inter", 
                  textAlign: "center",
                  marginTop: "17px"
                }}
              >
                {language === "pt-BR" ? "Que tal pesquisar novamente por outro termo?" : "How about searching again for another term?"}
              </Typography>
            </Grid>
          </Grid>
        ) : loading ? (
          <DrivaCircularProgress language={language}/>
        ) : (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ height: "350px" }}
          >
            <Grid
              container
              item
              direction="column"
              justifyContent="center"
              alignItems="center"
              padding = "50px"
            >
              <img src={searchSVG} alt="searchIcon" />
              <Typography style={{ fontWeight: "600", fontSize: "20px", fontFamily: "Inter", marginTop: "10px", color: "#101828" }}>
                {language === "pt-BR" ? "Procure por empresas" : "Search for companies"} 
              </Typography>
              <Typography
                style={{ 
                  fontSize: "16px", 
                  color: "#667085", 
                  fontFamily: "Inter", 
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                {language === "pt-BR" ? "Busque empresas pelo nome, CNPJ, nome do sócios ou a URL do site." : "Search for companies by name, CNPJ, name of the partners or the URL of the website."} 
              </Typography>
            </Grid>
          </Grid>
        )}
        {open && (
          <MoreInfoSearch
            open={open}
            handleClose={handleClose}
            Transition={Transition}
            data={data}
            language={language}
          />
        )}
      </Grid>
    </React.Fragment>
  );
}

