import {
  Grid,
  Typography,
  Divider,
  Tooltip,
  ButtonBase,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';

import { 
  EmailOutlined, 
  Facebook, 
  ForwardToInboxOutlined, 
  Instagram, 
  Language, 
  LinkedIn, 
  PeopleAltOutlined, 
  PhoneAndroidOutlined, 
  PhoneForwardedOutlined 
} from "@mui/icons-material";

export function Contatos(props) {
  const {language} = props;

  useEffect(() => {}, [ props?.url]);

  function ClickToCopy(props) {
    const { item } = props;
    return (
      <Tooltip title="Clique para Copiar">
        <ButtonBase
          onClick={() => navigator.clipboard.writeText(`${item}`)}
          style={{textAlign: "left"}}
        >
          <Grid container>
            <Typography
              style={{
                fontSize: "12px",
                color: "#1D2939",
                fontFamily: "Inter",
                fontWeight: 500,
                cursor: "pointer",
              }}
            >
              {item}
            </Typography>
          </Grid>
        </ButtonBase>
      </Tooltip>
    );
  }

  return (
    <React.Fragment>
      <Grid 
        container 
        flexDirection="column" 
        flexWrap="nowrap" 
        style={{
          padding: "31px 18px 0px 18px"
        }}
      >
        <Grid style={{backgroundColor: "#0033550D", borderRadius: "5px"}}>

          <Typography
            style={{
              fontSize: "12px", 
              fontFamily: "Inter",
              color: "#475467",
              margin: "12px",
            }}
          >
            {language === "pt-BR" ? 
              "Os dados de contato da versão gratuita do app são menos assertivos que a versão profissional." :
              "The contact details of the free version of the app are less assertive than the professional version."
            }
          </Typography>
          <a
            href="https://app.datadriva.com/reset-password"
            target="__blank"
            style={{
              textDecoration: "none", 
              fontFamily: "Inter", 
              fontSize: "14px", 
              fontWeight: 500, 
              color: "#F07F2D"
            }}
          >
            <Grid container direction="row" alignItems="center" style={{margin: "12px"}}> 
              {language === "pt-BR" ?  "Saiba mais" : "Learn more"}<ArrowForwardOutlinedIcon style={{color: "#F07F2D", fontSize: "20px", marginLeft: "8px"}}/>
            </Grid>
          </a>
        </Grid>
      </Grid>
      <Grid style={{padding: "30px 18px 32px 18px"}}>
        <Grid container flexDirection="row" flexWrap="nowrap">
          <CallOutlinedIcon/>
          <Typography 
            style={{
              marginLeft: "8px", 
              fontSize: "16px", 
              fontFamily: "Inter", 
              fontWeight: 600, 
              color: "#1D2939"
            }}
          >
            {language === "pt-BR" ?  "Telefones" : "Telephones"}
          </Typography>
        </Grid>
        <Grid container flexDirection="column">

          {props.responseCompany?.telefones?.map((telefones, index) => (
            <Grid style={{marginTop: "18px"}} key={`telefones-${index}`}>
              <Grid 
                container 
                flexDirection="row" 
                flexWrap="nowrap" 
                justifyContent="space-between" 
                alignItems="center"
              >
                <ClickToCopy
                  item={
                    telefones.telefone_completo
                      ? telefones.telefone_completo
                      : "Não consta"
                  }
                />
                <IconButton href={`tel:${telefones.telefone_completo}`}> 
                  <PhoneForwardedOutlined style={{color: "#F07F2D", fontSize: "20px"}}/>
                </IconButton>
              </Grid>
            </Grid> 
          ))}
        </Grid>
      </Grid>

      <Divider/>

      <Grid style={{padding: "30px 18px 32px 18px"}}>
        <Grid container flexDirection="row" flexWrap="nowrap">
          <EmailOutlined/>
          <Typography 
            style={{
              marginLeft: "8px", 
              fontSize: "16px", 
              fontFamily: "Inter", 
              fontWeight: 600, 
              color: "#1D2939"
            }}
          >
            Emails
          </Typography>
        </Grid>
        <Grid container flexDirection="column">
          {props.responseCompany?.emails?.map((emails,index)=>(
            <Grid style={{marginTop: "18px"}} key={`email-${index}`}>
              <Grid 
                container 
                flexDirection="row" 
                flexWrap="nowrap" 
                justifyContent="space-between" 
                alignItems="center"
              >
                <ClickToCopy
                  item={
                    emails.email
                      ? emails.email
                      : "Não consta"
                  }
                />
                <IconButton href={`mailto:${emails.email}`}> 
                  <ForwardToInboxOutlined style={{color: "#F07F2D", fontSize: "20px"}}/>
                </IconButton>
              </Grid>
            </Grid> 
          ))}
        </Grid>
      </Grid>

      <Divider/>

      <Grid style={{padding: "30px 18px 32px 18px"}}>
        <Grid container flexDirection="row" flexWrap="nowrap">
          <PhoneAndroidOutlined/>
          <Typography 
            style={{
              marginLeft: "8px", 
              fontSize: "16px", 
              fontFamily: "Inter", 
              fontWeight: 600, 
              color: "#1D2939"
            }}
          >
            {language === "pt-BR" ?  "Redes sociais" : "Social media"}
          </Typography>
        </Grid>
        <Grid container flexDirection="column" style={{marginTop: "18px"}} >
          <Grid container flexDirection="row" alignItems="center" style={{marginBottom: "16px"}}>
            <Language style={{color: "#838383", fontSize: "16px", marginLeft: "4px"}}/>
            {props.url ? (
              <a
                href={`https://${props.url}`}
                target="_blank"
                style={{
                  fontSize: "12px",
                  fontWeight: 500,
                  fontFamily: "Inter",
                  color: "#2F2F2F",
                  marginLeft: "8px"
                }}
              >
                {props.url}
              </a>
            ) : (
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: 500,
                  fontFamily: "Inter",
                  color: "#2F2F2F",
                  marginLeft: "8px"
                }}
              >
                Não consta
              </Typography>
            )}
          </Grid>
          <Grid container flexDirection="row" alignItems="center" style={{marginBottom: "16px"}}>
            <LinkedIn style={{color: "#838383", fontSize: "16px", marginLeft: "4px"}}/>
            {props.responseCompany?.linkedin?.length > 0 ? (
              <a
                href={props.responseCompany?.linkedin[0]?.url}
                target="_blank"
                style={{
                  fontSize: "12px",
                  fontWeight: 500,
                  fontFamily: "Inter",
                  color: "#2F2F2F",
                  marginLeft: "8px"
                }}
              >
                {props.responseCompany?.linkedin[0]?.url}
              </a>
            ) : (
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: 500,
                  fontFamily: "Inter",
                  color: "#2F2F2F",
                  marginLeft: "8px"
                }}
              >
                Não consta
              </Typography>
            )}
          </Grid>
          <Grid container flexDirection="row" alignItems="center" style={{marginBottom: "16px"}}>
            <Instagram style={{color: "#838383", fontSize: "16px", marginLeft: "4px"}}/>
            {props.responseCompany?.instagram?.length > 0 ? (
              <a
                href={props.responseCompany?.instagram[0]?.url}
                target="_blank"
                style={{
                  fontSize: "12px",
                  fontWeight: 500,
                  fontFamily: "Inter",
                  color: "#2F2F2F",
                  marginLeft: "8px"
                }}
              >
                {props.responseCompany?.instagram[0]?.url}
              </a>
            ) : (
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: 500,
                  fontFamily: "Inter",
                  color: "#2F2F2F",
                  marginLeft: "8px"
                }}
              >
                Não consta
              </Typography>
            )}
          </Grid>
          <Grid container flexDirection="row" alignItems="center">
            <Facebook style={{color: "#838383", fontSize: "16px", marginLeft: "4px"}}/>
            {props.responseCompany?.facebook?.length > 0 ? (
              <a
                href={props.responseCompany?.facebook[0]?.url}
                target="_blank"
                style={{
                  fontSize: "12px",
                  fontWeight: 500,
                  fontFamily: "Inter",
                  color: "#2F2F2F",
                  marginLeft: "8px"
                }}
              >
                {props.responseCompany?.facebook[0]?.url}
              </a>
            ) : (
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: 500,
                  fontFamily: "Inter",
                  color: "#2F2F2F",
                  marginLeft: "8px"
                }}
              >
                Não consta
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Divider/>

      <Grid style={{padding: "30px 18px 100px 18px"}}>
        <Grid container flexDirection="row" flexWrap="nowrap">
          <PeopleAltOutlined/>
          <Typography 
            style={{
              marginLeft: "8px", 
              fontSize: "16px", 
              fontFamily: "Inter", 
              fontWeight: 600, 
              color: "#1D2939"
            }}
          >
            {language === "pt-BR" ?  "Sócios" : "Partners"}
          </Typography>
        </Grid>
        {props.responseCompany?.socios?.map((socios,index)=>(
          <React.Fragment key={`socios-${index}`}>
            <Grid 
              container 
              flexDirection="column" 
              style={{
                marginTop: "18px", 
                backgroundColor: "#FFF", 
                border: "1px solid #EAECF5",
                borderRadius: "8px"
              }}
            >
              <Grid style={{margin: "16px"}}>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                    fontFamily: "Inter",
                    color: "#2F2F2F"
                  }}
                >
                  {socios.nome_socio}
                </Typography>
                <Typography
                  style={{
                    fontSize: "12px",
                    fontFamily: "Inter",
                    color: "#838383"
                  }}
                >
                  {language === "pt-BR" ?  "Entrou em" : "Joined in"} {socios.data_entrada_sociedade}
                </Typography>

              </Grid>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </React.Fragment>
  );
}
