import { Button, Dialog, Divider, Grid, Slide, Switch, Typography } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LoginIcon from "@mui/icons-material/Login";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import React, { useState } from "react";
import {
  getLocalStorage,
  getLocalStorageChecked,
  setLocalStorageChecked,
} from "../../../utils/localStorage";
import { connectLinkedin } from "../../../services/queryService";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function MaisOpçoes(props) {
  const { language, open, handleClose } = props;
  const [checked, setChecked] = React.useState(
    getLocalStorageChecked("workflow") === "true" ? true : false
  );
  const [connecting, setConnecting] = useState(false);
  const [error, setError] = useState(false);
  const [debounce, setDebounce] = useState();
  const userID = getLocalStorage("id");

  const handleChange = (event) => {
    setError(false);
    setChecked(event.target.checked);
    if (event.target.checked) setConnecting(true);
    setLocalStorageChecked("workflow", event.target.checked);
    window.parent.postMessage({ checked: event.target.checked, event: "workflowChecked" }, "*");
  };

  window.addEventListener("message", async (e) => {
    let urlTrusted = window.document.location.search.slice(5);
    let trustedOrigins = [`https://${urlTrusted}`, `http://${urlTrusted}`]
    if (e.data.event === "getLogin" && trustedOrigins.includes(e.origin)) {
      setDebounce((prev) => {
        clearTimeout(prev);
        return setTimeout(() => {
          handleCookiesWorkflow(e);
        }, 350);
      });
    }
  });

  const handleCookiesWorkflow = async (e) => {
    if (e.data.event === "cookiesWorkflow" && userID) {
      console.log("Handling Cookies");
      try {
        await connectLinkedin(e.data.cookies, getLocalStorage("token")?.data);
        setChecked(true);
        setLocalStorageChecked("workflow", true);
      } catch (error) {
        setError(true);
        setChecked(false);
        setLocalStorageChecked("workflow", false);
        setTimeout(() => setError(false), 10 * 1000);
      }
      setConnecting(false);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Grid
          sx={{
            marginX: "5px",
            backgroundColor: "#FFFFFF",
            boxShadow: "none",
          }}
        >
          <Button
            onClick={handleClose}
            disableRipple
            startIcon={
              <ArrowBackIosIcon
                style={{
                  paddingLeft: "10px",
                  fontSize: "14px",
                  color: "#FF7A00"
                }}
              />
            }
          >
            <Typography
              style={{
                fontFamily: "Inter",
                textTransform: "capitalize",
                alignItems: "center",
                display: "flex",
                fontSize: "16px",
                fontWeight: "500",
                color: "#FF7A00",
              }}
            >
              {language === "pt-BR" ? "Voltar" : "Back"}
            </Typography>
          </Button>
        </Grid>
        <Divider />
        <Grid
          style={{
            padding: "20px 14px 0px 14px",
          }}
        >

          <Button
            variant="text"
            style={{
              fontSize: "14px",
              color: "#475467",
              textTransform: "none",
              marginTop: "18px",
              marginBottom: "18px",
            }}
            disableRipple
            href="https://driva.tawk.help/"
            target="_blank"
          >
            <HelpOutlineIcon
              style={{
                color: "#475467",
                fontSize: "15px",
                marginRight: "12px"
              }}
            />
            {language === "pt-BR" ? "Central de ajuda" : "Help center"}
          </Button>
          <Divider />
          <Button
            variant="text"
            style={{
              fontSize: "14px",
              color: "#475467",
              textTransform: "none",
              marginTop: "18px",
              marginBottom: "18px",
            }}
            disableRipple
            href="https://app.driva.io/"
            target="_blank"
          >

            <LoginIcon style={{ color: "#475467", fontSize: "15px", marginRight: "12px" }} /> {language === "pt-BR" ? "Acessar o App Driva" : "Access the Driva App"}
          </Button>
          <Divider />
          <Grid
            style={{
              marginTop: "18px",
              marginBottom: "18px",
              paddingLeft: "10px",
            }}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <ManageAccountsIcon
              style={{ color: "#475467", fontSize: "15px", marginRight: "12px" }}
            />
            <Typography
              style={{
                fontSize: "14px",
                color: "#475467",
              }}
            >
              {language === "pt-BR" ? "Configurar Sales Assistant" : "Set up Sales Assistant"}
            </Typography>
            <Switch
              checked={checked}
              onChange={handleChange}
              disabled={connecting}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid>
          {error && (
            <div style={{ marginBottom: "18px", paddingLeft: "10px" }}>
              <Typography
                style={{
                  fontSize: "14px",
                  color: "#c92a2a",
                }}
              >
                Could not connect: check if you are connected to linkedin in this
                browser{" "}
              </Typography>
            </div>
          )}
          {connecting && (
            <div style={{ marginBottom: "18px", paddingLeft: "10px" }}>
              <Typography
                style={{
                  fontSize: "14px",
                  color: "#475467",
                }}
              >
                Connecting...
              </Typography>
            </div>
          )}
          <Divider />
        </Grid>
      </Dialog>
    </React.Fragment>
  );
}

