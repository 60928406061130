import { AppBar, Grid, IconButton, Toolbar, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';

export default function Footer(props){
  const {url, selectedPanel, setSelectedPanel, language} = props;
  return(
    <AppBar
      position="fixed"
      sx={{
        top: "auto",
        bottom: 0,
        backgroundColor: "#FFFFFF",
        zIndex: "999999999",
      }}
    >
      <Toolbar style={{ padding: "0px" }}>
        <Grid container justifyContent="space-around" alignItems="center">
          {url && (
            <Grid item>
              <IconButton
                disableRipple={true}
                size="large"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  fontSize: "12px",
                  color: `${selectedPanel === "descubra" ? "#F07F2D" : "#475467"}`
                }}
                onClick={() => {
                  setSelectedPanel("descubra");
                }}
              >
                <BusinessIcon />
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  {language === "pt-BR" ? "Empresas" : "Companies"}
                </Typography>
              </IconButton>
            </Grid>
          )}

          <Grid item>
            <IconButton
              disableRipple={true}
              size="large"
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
                color: `${selectedPanel === "contacts" ? "#F07F2D" : "#475467"}`
              }}
              onClick={() => {
                setSelectedPanel("contacts")
              }}
            >
              <PeopleIcon />
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "10px",
                  fontWeight: 500,
                }}
              >
                {language === "pt-BR" ? "Contatos" : "Contacts"}
              </Typography>
            </IconButton>
          </Grid>

          <Grid item>
            <IconButton
              disableRipple={true}
              size="large"
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
                color: `${selectedPanel === "search" ? "#F07F2D" : "#475467"}`
              }}
              onClick={() => {
                setSelectedPanel("search")
              }}
            >
              <SearchIcon />
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "10px",
                  fontWeight: 500,
                }}
              >
                {language === "pt-BR" ? "Pesquise" : "Search"}
              </Typography>
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar> 
  )
}