import React, { useCallback, useEffect, useState } from "react";
import {Box} from "@mui/material";
import { Descubra } from "../../components/modules/Descubra/Descubra";
import { Search } from "../../components/modules/Pesquisa/Search";
import { SaveEvent, linkedinEnrichment } from "../../services/queryService";
import { multipartApiGateway } from "../../services/axiosClients";
import DrivaCircularProgress from "../../components/common/CircularProgress";
import Header from "../../components/common/Header";

import Contacts from "../../components/modules/Contacts/Contacts";
import Footer from "../../components/common/Footer";
import NotFoundInfo from "../../components/common/NotFoundInfo";

export default function Main(props) {
  const {
    language,
    url,
    urlHref,
    urlPathName,
    linkedin,
    authenticated
  } = props;
  const [selectedPanel, setSelectedPanel] = useState("descubra");
  const [loading, setLoading] = useState(true);
  const [responseCompany, setResponseCompany] = useState({});

  //const [linkedin, setLinkedin] = useState(0); // utilizado para Teste
  //const url = "driva.io"; //utilizado para Teste

  const onFocus = useCallback(() => {
    const tempoEntrada = Date.now();
    sessionStorage.setItem("tempoEntrada", tempoEntrada);
    SaveEvent("email", "Entrou no site", { time: tempoEntrada, url: url });
  }, [url]);

  const onBlur = useCallback(() => {
    const tempoAtual = Date.now();
    SaveEvent("email", "Saiu no site", { time: tempoAtual, url: url });
  }, [url]);
  
  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    onFocus();

    const handleTabClose = (event) => {
      event.preventDefault();

      onBlur();
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("beforeunload", handleTabClose);
      window.removeEventListener("blur", onBlur);
    };
    
  }, [onBlur, onFocus]);

  useEffect(() => {
    if (url && authenticated) {
      let body = new FormData();
      body.append("size", "1");
      body.append("page", "0");
      try {
        if(linkedin == 0){
          body.append(
            "query",
            `{"$source":"empresas_do_brasil","$and":[{"sites.site":"${url.replace(
              /^(www[1-9]?\.)/,
              ""
            )}"}]}`
          );
        }
        else {
          body.append(
            "query",
            `{"$source":"empresas_do_brasil", "$or": [
              {
                  "razao_social.trigram": "${linkedin}"
              },
              {
                  "nome_fantasia.trigram": "${linkedin}"
              },
              {
                "sites.site": "${linkedin}"
              }
            ]}`
          );
        }
        multipartApiGateway
          .post(`/storage_service/v1/search`, body, {params: { credits_type: "free" }})
          .then((response) => {
            setResponseCompany(response.data[0]);
            setLoading(false);
          });
      } catch (err) {
        setLoading(false);
      }
    }
  }, [url, linkedin, authenticated]);

  useEffect(() => {
    if(urlHref?.startsWith("https://www.linkedin.com/company")){
      const urlPathNameArray = urlPathName.split("/");
      const urlPathNameSliced = urlPathNameArray.slice(1,3);
      const linkedinURL = {
        "companiesPayload": [
          {
            "linkedin_url": [
              `linkedin.com/${urlPathNameSliced[0]}/${urlPathNameSliced[1]}`
            ]
          }
        ]
      };
      linkedinEnrichment(linkedinURL);
    }
  }, [urlHref]);

  const renderPanel = (panel) => {
    switch (panel) {
      case "descubra":
        return <Descubra url={url} responseCompany={responseCompany} linkedin={linkedin} language={language}/>
      case "search":
        return <Search language={language}/>
      case "contacts":
        return <Contacts language={language} responseCompany={responseCompany} url={url} linkedin={props.linkedin}/>
      default:
        return <Descubra url={url} responseCompany={responseCompany} linkedin={linkedin} language={language}/>
    }
  };

  return (
    <Box>
      <Header language={language}/>
      {loading || !authenticated ? (
        <DrivaCircularProgress language={language}/>
      ) : (
        <>
          {(!url || !responseCompany) && selectedPanel !== "search" ? (
            <NotFoundInfo language={language}/>
          ) : (
            <>
              {renderPanel(selectedPanel)}
            </>
          )}
        </>
      )}
      <Footer 
        url={url} 
        setSelectedPanel={setSelectedPanel} 
        selectedPanel={selectedPanel} 
        language={language}
      />
    </Box>
  );
}

