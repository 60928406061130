import React from "react";
import styled from "styled-components/macro";

import { spacing } from "@material-ui/system";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Grid,
  TextField as MuiTextField,
  Typography,
  Box,
} from "@material-ui/core";

import { Alert as MuiAlert } from "@material-ui/lab";
import { Button } from "@mui/material";
import { SaveEvent, signIn } from "../../services/queryService";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);

function SignIn(props) {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("E-mail inválido")
      .max(255)
      .required("E-mail obrigatório"),
    password: Yup.string().max(255).required("Senha obrigatória"),
  });

  async function handleFormikSubmit(
    values,
    { setErrors, setStatus, setSubmitting }
  ) {
    try {
      await signIn({
        email: values.email.toLowerCase(),
        password: values.password,
      }).then((res) => {
        if (res.status === "OK") {
          SaveEvent(
            values.email.toLowerCase(),
            "Usuario logou na extensão",
            "Login"
          );
          props.setSignIn(true);
        }
      });
    } catch (error) {
      const message = error.message || "Algo deu errado";
      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  }

  return (
    <React.Fragment>
      <Box style={{ overflowX: "hidden" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ height: "100vh" }}
        >
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <img
                  alt="Logo da driva"
                  style={{ height: "90px" }}
                  src="https://app.datadriva.com/static/img/brands/logo.png"
                />
              </Grid>
              <Grid item>
                <Typography style={{fontFamily: "Inter", fontSize: "16px", fontWeight: 500, marginTop: "20px"}}>
                  Entre na sua conta
                </Typography>
              </Grid>
            </Grid>
            <Formik
              initialValues={{ email: "", password: "", submit: false }}
              validationSchema={validationSchema}
              onSubmit={handleFormikSubmit}
            >
              {function ({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) {
                return (
                  <form noValidate onSubmit={handleSubmit}>
                    {errors.submit && (
                      <Alert mt={2} mb={1} severity="warning">
                        {errors.submit}
                      </Alert>
                    )}
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item xs={11}>
                        <TextField
                          type="email"
                          name="email"
                          id="email"
                          label="Email"
                          size="small"
                          variant="outlined"
                          value={values.email}
                          error={Boolean(touched.email && errors.email)}
                          fullWidth
                          helperText={touched.email && errors.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          my={2}
                          InputProps={{
                            style: {
                              borderRadius: 2,
                            },
                          }}
                        />
                        <TextField
                          type="password"
                          id="password"
                          name="password"
                          label="Senha"
                          variant="outlined"
                          size="small"
                          autoComplete="current-password"
                          value={values.password}
                          error={Boolean(touched.password && errors.password)}
                          fullWidth
                          helperText={touched.password && errors.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          my={2}
                          InputProps={{
                            style: {
                              borderRadius: 2,
                            },
                          }}
                        />
                        <a
                          href="https://app.datadriva.com/reset-password"
                          target="__blank"
                          style={{
                            textDecoration: "none", 
                            fontFamily: "Inter", 
                            fontSize: "14px", 
                            fontWeight: 500, 
                            color: "#F07F2D"
                          }}
                        >
                          Esqueci a senha
                        </a>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          disabled={isSubmitting}
                          style={{
                            marginTop: 30,
                            borderRadius: 2,
                            backgroundColor: "#035",
                            fontFamily: "Inter",
                            textTransform: "none",
                          }}
                        >
                          Entre
                        </Button>
                        <Typography style={{fontSize: "14px", fontFamily: "Inter", color: "#667085", marginTop: "10px"}}>
                          Não possui uma conta? 
                          <a 
                            target="_blank" 
                            href="https://app.driva.io/sign-up/redirect?c=driva" 
                            style={{
                              textDecoration: "none", 
                              fontFamily: "Inter", 
                              fontSize: "14px", 
                              fontWeight: 500, 
                              color: "#F07F2D",
                              marginLeft: "5px"
                            }}
                          >
                            Se cadastre!
                          </a>
                        </Typography>
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default SignIn;
