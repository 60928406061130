import { useState } from "react";
import Main from "./pages/Main/Main";
import SignIn from "./pages/SignIn";
import { authenticate } from "./services/queryService";

export default function App(props) {
  const {
    loginScreen, 
    url, 
    refreshToken, 
    userCompany, 
    page, 
    company, 
    urlHref, 
    urlPathName, 
    linkedin, 
    language
  } = props;
  const [loggedIn, setLoggedIn] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);

  async function getToken() {
    await authenticate(props.userCompany, props.refreshToken);
    setAuthenticated(true);
    // window.top.postMessage({
    //   event: "setLogin", login: response
    // }, "*");
  }

  if (props.refreshToken || props.userCompany) {
    getToken();
  }

  return (
    <>
      {loginScreen ?
        loggedIn ?
          <Main url={url} />
          : <SignIn setSignIn={setLoggedIn} />
        : <Main
          refreshToken={refreshToken}
          userCompany={userCompany}
          page={page}
          company={company}
          url={url}
          urlHref={urlHref}
          urlPathName={urlPathName}
          linkedin={linkedin}
          language={language}
          authenticated={authenticated}
        />
      }
    </>
  )
}
